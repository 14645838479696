<template>
  <div>
    <div class="flx-row flx-wr">
      <draggable
        class="list-group"
        tag="ul"
        v-model="pictureList"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
      >
        <div v-for="(item,index) in pictureList" :key="index" class="drag-img-item">
          <img :src="picsPath(item)" />
          <div class="drag-mask">
            <i class="el-icon-zoom-in" @click="imgPreview(picsPath(item))"></i>
            <i class="el-icon-delete" @click="handleDel(index)"></i>
          </div>
        </div>
      </draggable>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
      <UploadPics
        :uploadType="'pics'"
        :imgLimit="6"
        :imgSize="''"
        :imgsPreview="subImgStr"
        @getImgData="getImgData"
        :drag="true"
      />
    </div>
    <span class="advise-text">建议图片尺寸{{imgSize}}，图片大小不超过500kb，最多添加6张</span>
  </div>
</template>
<script>
import UploadPics from "../UploadPics";
import draggable from "vuedraggable";
export default {
  props: ["imgSize", "pictures"],
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      drag: false,
      subImgStr: "",
      pictureList: []
    };
  },
  components: {
     draggable,
    UploadPics
  },
  methods: {
    // 图片预览
    // imgPreview(url) {
    //   this.dialogImageUrl = url;
    //   this.dialogVisible = true;
    // },
    // 获取图片数据
    getImgData(imgData, type) {
      // console.log("getImgData", imgData, type);
      type == "pics" ? (this.pictureList = imgData.subImgs.split(",")) : "";
    },
    // 删除
    handleDel(index) {
      this.pictureList.splice(index, 1);
      this.subImgStr = this.pictureList.join(",");
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  watch: {
    pictures() {
      // console.log(1);
      this.pictureList = JSON.parse(JSON.stringify(this.pictures));
    },
    pictureList() {
      // console.log(2);
      this.subImgStr = this.pictureList.join(",");
      this.$emit("getPictures", this.pictureList); 
    }
  }
};
</script>
<style lang="scss" scoped>
.drag-img-item {
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  margin: 0 8px 8px 0;
  position: relative;
}
.drag-img-item img {
  width: 100%;
  height: 100%;
}
.drag-img-item:hover .drag-mask {
  opacity: 1;
  transition: all 0.3s;
}
.drag-mask {
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  font-size: 20px;
  color: #fff;
}
.drag-mask i {
  cursor: pointer;
}
.drag-mask i:first-child {
  margin-right: 15px;
}
.list-group {
  display: flex;
  padding: 0;
  margin: 0;
}
.advise-text {
  color: #999;
  font-size: 14px;
}
</style>
<!-- 详情与编辑、添加都放同一页 -->
<template>
  <div>
    <!-- 基本信息 -->
    <div class="pad-container">
      <el-form class="mt-24" label-width="180px" :model="act_form" :rules="rules" ref="act_form">
        <p class="fs-18 c-9 fw-7">基础信息</p>
        <el-form-item label="商品分类" prop="goodsClassifyId">
          <el-cascader
            v-model="act_form.goodsClassifyId"
            :show-all-levels="false"
            :options="mainSpecialOptions"
            class="form-input"
            :props="props"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="商品名称" prop="goodsName">
          <el-input
            class="form-input"
            placeholder="请输入商品标题"
            v-model="act_form.goodsName"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-divider></el-divider>
        <p class="fs-18 c-9 fw-7">详情信息</p>
        <!-- <el-form-item label="商品名称（来源ERP）" prop="goodsCode">
          <el-input
            class="form-input"
            placeholder="请输入商品标题"
            v-model="act_form.fjGoodsName"
            maxlength="20"
            show-word-limit
            :disabled='isAddNew'
          ></el-input>
        </el-form-item> -->
        <el-form-item label="商品条码(UPC)" prop="goodsBarcode">
          <el-input
            class="form-input"
            placeholder="请输入商品条码(UPC)"
            v-model="act_form.goodsBarcode"
            :disabled='isAddNew'
          ></el-input>
        </el-form-item>
        <el-form-item label="商品编码" prop="goodsCode">
          <el-input class="form-input" placeholder="请输入商品编码" v-model="act_form.goodsCode" :disabled='isAddNew'></el-input>
        </el-form-item>
        <el-form-item label="所属门店" prop="storeId">
          <el-select v-model="act_form.storeId" placeholder="请选择" class="form-input" :disabled='isAddNew'>
            <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.storeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上下架状态" prop="status">
          <el-switch
            v-model="act_form.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="上架"
            inactive-text="下架"
            :active-value="1"
            :inactive-value="0">
          </el-switch>
        </el-form-item>
        <el-form-item label="商品排序" prop="goodsBarcode">
          <el-input
            class="form-input"
            placeholder="请输入排序序号"
            v-model="act_form.sort"
            :disabled='isAddNew'
          ></el-input>
        </el-form-item>
        <el-form-item label="实价" prop="actualPrice">
          <el-input placeholder="请输入实价" v-model="act_form.actualPrice" class="form-input" :disabled='isAddNew'>
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="原价" prop="originalPrice">
          <el-input
            placeholder="请输入原价"
            v-model="act_form.originalPrice"
            class="form-input"
            :disabled='isAddNew'
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="库存" prop="stock">
          <el-input class="form-input" placeholder="请输入库存" v-model="act_form.stock" :disabled='isAddNew'></el-input>
        </el-form-item>
        <el-form-item label="重量" prop="goodsWeight">
          <el-input v-model="act_form.goodsWeight" class="form-input" :disabled='isAddNew'>
            <template slot="append">g</template>
          </el-input>
        </el-form-item>
        <el-form-item label="商品角标">
          <el-select
            v-model="act_form.cornerMarkId"
            placeholder="请选择"
            class="form-input"
            @change="cornerChange"
            clearable
          >
            <el-option
              v-for="(item,index) in cornerList"
              :key="index"
              :label="item.cornerMarkName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品副标题" prop="goodsSubhead">
          <el-input
            v-model="act_form.goodsSubhead"
            class="form-input"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="是否完善信息" prop="status">
          <el-switch
            v-model="act_form.isComplete"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="是"
            inactive-text="否"
            :active-value="1"
            :inactive-value="0">
          </el-switch>
        </el-form-item>
        <el-form-item label="是否推荐商品" prop="status">
          <el-switch
            v-model="act_form.isRecommend"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="是"
            inactive-text="否"
            :active-value="1"
            :inactive-value="0">
          </el-switch>
        </el-form-item>
        <el-form-item label="商品主图" prop="goodsImgs">
          <UploadPics
            :uploadType="'main'"
            :imgLimit="1"
            :imgSize="'270*270'"
            :imgsPreview="act_form.goodsImgs"
            @getImgData="getImgData"
            :toEnable="!showDetails"
          />
        </el-form-item>
        <el-form-item label="商品辅图" prop="subImgs">
          <DragImgs @getPictures="getPictures" :imgSize="'750*750'" :pictures="pictures" />
        </el-form-item>
        <el-form-item label="商品详情">
          <tinymce v-model="act_form.goodsDescribe" :height="300" />
        </el-form-item>
      </el-form>
      <div class="flx-row ali-c js-fe mt-50">
        <el-button plain class="form-button" @click="cancel">取消</el-button>
        <el-button class="form-button" type="primary" @click="submit">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import request from "@/utils/request";
import Pagination from "@/components/Pagination/index";
import DragImgs from "@/components/DragImgs";
import UploadPics from "@/components/UploadPics";
import * as Interface from "@/api/common";
import {
  // queryGoodsType,
  listOfPage,//获取门店列表
  // delGoodsFreshInfo,
  // upAndDownGoods,
  // queryFJGoodsList,
  saveOrUpdate,//保存或者更新
  goodsSupermarkertPage,//查询商品分页
  deleteByGoodId,//根据商品id删除商品
  downOrUp,//上下架商品
  getInfoById,//根据商品id获取商品详情
} from "@/api/market/goods_list";
export default {
  components: {
    Pagination,
    DragImgs,
    UploadPics,
    Tinymce
  },
  data() {
    var validateGroupLimit = (rule, value, callback) => {
      if (!String(value)) {
        callback(new Error("成团人数不能为空"));
      } else if (!/(^[1-9]\d*$)/.test(value) || value == 0) {
        callback(new Error("请输入大于0的整数"));
      } else {
        callback();
      }
    };
    return {
      shopOptions: [
        {
          label: "江北店",
          value: 1
        },
        {
          label: "江源店",
          value: 2
        }
      ],
      pictures: [],
      act_form: {
        goodsType: 1,
        subImgs: "",
        goodsImgs: "",
        goodsDescribe: "",
        goodsWeight: "",
        stock: "",
        actualPrice: "",
        goodsClassifyId: "",
        originalPrice: "",
        goodsBarcode: "",
        goodsCode: "",
        goodsSubhead: "",
        goodsName: "",
        storeId: "",
        cornerMarkId: "",
        fjGoodsName: "",
        status:1,//上下架状态
        isComplete:1,
        isRecommend:1,
        sort:'',
      },
      rules: {
        goodsImgs: [
          { required: true, message: "主图不能为空", trigger: "blur" }
        ],
        goodsName: [
          { required: true, message: "商品名称不能为空", trigger: "blur" }
        ],
        goodsSubhead: [
          { required: true, message: "商品副标题不能为空", trigger: "blur" }
        ],
        // fjGoodsName: [
        //   {
        //     required: true,
        //     message: "商品名称（来源ERP）不能为空",
        //     trigger: "blur"
        //   }
        // ],

        mainSpecial: [
          { required: true, message: "商品类目不能为空", trigger: "blur" }
        ],
        subImgs: [{ required: true, message: "辅图不能为空", trigger: "blur" }],
        goodsWeight: [
          { required: true, message: "重量不能为空", trigger: "blur" }
        ],
        stock: [{ required: true, message: "库存不能为空", trigger: "blur" }],
        actualPrice: [
          { required: true, message: "实价不能为空", trigger: "blur" }
        ],
        originalPrice: [
          { required: true, message: "原价不能为空", trigger: "blur" }
        ],
        storeId: [
          { required: true, message: "所属门店不能为空", trigger: "blur" }
        ],
        goodsCode: [
          { required: true, message: "商品编码不能为空", trigger: "blur" }
        ],
        goodsBarcode: [
          { required: true, message: "商品条码不能为空", trigger: "blur" }
        ],
        goodsClassifyId: [
          { required: true, message: "商品分类不能为空", trigger: "blur" }
        ]
      },
      showGoods: false,
      goodsTable: [],
      search_goods: "",
      search_barCode: "",
      hasSelected: false,
      addFlag: true,
      actId: "",
      total: 0,
      pageNum: 1,
      pageSize: 5,
      pageSizes: [5, 8, 10, 15],
      mainImgStr: "",
      showDetails: false,
      firstFlag: false,
      mainSpecialOptions: [],
      storeList: [],
      cornerList: [],
      props: {
        label: "classifyName",
        value: "id",
        children: "childList"
      },
      selected_temp: [], // 全局勾选数组
      isAddNew:false
    };
  },
  mounted() {
    this.getStoreList();
    this.queryAllCornerMark();
    let id = this.$route.query.id;
    let storeId = this.$route.query.storeId;
    id ? this.init(id, storeId) : this.queryGoodsType();
    // id ? this.getDetails(id) : ''
  },
  filters: {
    goodsTypeFilter(val) {
      switch (val) {
        case 1:
          return "商品";
        case 2:
          return "优惠券";
        case 3:
          return "商兑换券";
        default:
          return "--";
      }
    }
  },
  methods: {
    cornerChange() {
      this.$forceUpdate();
    },
    // 递归拿到父id
    getParentId(data) {
      data.map(item => {
        if (item.childList) {
          this.getParentId(item.childList);
        }
        if (item.id == this.act_form.classifyId) {
          this.act_form.goodsClassifyId = [item.parentId, item.id];
          delete this.act_form.classifyId;
        }
      });
    },
    // 数据初始化
    init(id, storeId) {
      Promise.all([this.getDetails(id, storeId), this.queryGoodsType()])
        .then(() => {
          this.getParentId(this.mainSpecialOptions);
        })
        .catch(error => {});
    },
    // 获取详情
    getDetails(id) {
      // return new Promise((resolve, reject) => {
      //   $http({
      //     url: `/shd-operation/lrGoodsInfo/queryFjGoodsDetail?goodsCode=${id}&storeId=${storeId}`,
      //     method: "get"
      //   })
      //     .then(res => {
      //       if (res.data.body) {
      //         this.act_form = res.data.body;
      //         this.act_form.cornerMarkId = res.data.body.cornerId;
      //         this.act_form.goodsType = 1;
      //         delete this.act_form.cornerId;
      //         this.pictures = this.act_form.subImgs.split(";");
      //         resolve();
      //       }
      //     })
          
      // });
      getInfoById(id).then(res=>{
        console.log(res.data)
        this.act_form = res.data.body
      })
    },
    // 查询所有角标
    queryAllCornerMark() {
      Interface.queryAllCornerMark({})
        .then(res => {
          this.cornerList = res.data.body.list;
        })
        
    },
    // 获取店铺列表
    getStoreList() {
      Interface.getStoreList()
        .then(res => {
          this.storeList = res.data.body.list;
        })
        
    },
    // 获取商品分类
    queryGoodsType() {
      return new Promise((resolve, reject) => {
        Interface.queryGoodsType({ goodsType: 2 })
          .then(res => {
            this.mainSpecialOptions = res.data.body;
            resolve();
          })
          .catch(err => {
            reject();
          });
      });
    },
    // 获取图片
    getPictures(subImgs) {
      this.act_form.subImgs = subImgs.join(";");
    },
    // 点击其他页
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    // 获取图片数据
    getImgData(imgData, type) {
      type == "main" ? (this.act_form.goodsImgs = imgData.mainImg) : "";
    },
    // 返回
    cancel() {
      this.$router.go(-1);
    },
    // 提交表单-验证
    submit() {
      this.$refs["act_form"].validate(valid => {
        if (!valid) {
          this.$message.warning("表单信息不完整（有误）");
          return;
        }
        this.submitForm();
      });
    },
    // 提交表单-提交
    submitForm() {
      let data = JSON.parse(JSON.stringify(this.act_form));
      if (Array.isArray(data.goodsClassifyId)) {
        data.goodsClassifyId =
          data.goodsClassifyId[data.goodsClassifyId.length - 1];
      }
      data.goodsWeight = this.removeDot(data.goodsWeight);
      data.actualPrice = this.removeDot(data.actualPrice);
      data.originalPrice = this.removeDot(data.originalPrice);
      let url = "";
      if (data.goodsId) {
        url = "/shd-operation/lrGoodsInfo/updateGoodsFreshInfo";
      } else {
        url = "/shd-operation/lrGoodsInfo/addLrGoodsFreshInfo";
      }
      // $http({
      //   url,
      //   method: "post",
      //   data
      // })
        saveOrUpdate(data).then(res => {
          this.$message.success(res.data.body);
          this.$router.go(-1);
        })
        
    }
  }
};
</script>
<style lang="scss" scoped>
.tip-text-info {
  color: #409eff;
  cursor: pointer;
}
.el-avatar /deep/ img {
  width: 100%;
}
</style>